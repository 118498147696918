/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Logo from '../assets/images/logo.svg';
import SearchField from './SearchField';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Container from '@material-ui/core/Container';
import {useDispatch, useSelector} from 'react-redux';
// noinspection ES6CheckImport
import {useHistory, useLocation} from 'react-router-dom';
import {setResetSearchFilters, toggleShoppingCart} from '../redux/actions';
// noinspection ES6CheckImport
import {searchStateToUrl} from '../modules/utils';
import {SELECTOR_SHOPPING_CART_PRODUCTS_COUNT} from '../redux/selectors/shoppingCart';
import Divider from '@material-ui/core/Divider';
import ShoppingCart from './ShoppingCart';
import CategoriesTopBar from './CategoriesTopBar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {Box} from '@material-ui/core';
import {MenuDrawer} from './MenuDrawer';
import {SELECTOR_SEARCH_QUERY} from '../redux/selectors/search';
import {setSearchQuery} from '../redux/actions';
import Button from '@material-ui/core/Button';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import PhoneIcon from '@material-ui/icons/Phone';
import useCommercialProfile from './commercial/hooks/useCommercialProfile.hook';
import PromotionalMessage from './PromotionalMessage';

function Search() {
  const query = useSelector(SELECTOR_SEARCH_QUERY);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const urlSearchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (urlSearchParams.has('configure[query]')) {
      dispatch(setSearchQuery(urlSearchParams.get('configure[query]')));
    } else {
      dispatch(setSearchQuery(''));
    }
  }, []);

  function onSearch() {
    const location = {pathname: '/b'};
    const params = {configure: {query, page: 1}};
    const url = searchStateToUrl(location, params);
    history.push(url);
  }

  return (
    <SearchField
      value={query}
      variant="white"
      handleSearch={onSearch}
      onChange={(event) => dispatch(setSearchQuery(event.currentTarget.value))}
    />
  );
}

function ShoppingCartButton() {
  const countCart = useSelector(SELECTOR_SHOPPING_CART_PRODUCTS_COUNT);

  const dispatch = useDispatch();

  return (
    <>
      <IconButton onClick={() => dispatch(toggleShoppingCart(true))} color="inherit">
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          badgeContent={`${countCart}`}
          color="secondary"
        >
          {/* <ShopIcon color="primary"/> */}
          <ShoppingCartOutlinedIcon />
        </Badge>
      </IconButton>
      <ShoppingCart color="primary" />
    </>
  );
}

const useStylesHeader = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 80,
  },
  containerTools: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  containerLogo: {
    // display: 'grid',
    // gridTemplateColumns: 'repeat(1, 1fr)',
    // gridGap: theme.spacing(2),
    // gridAutoRows: 'minmax(100px, auto)',
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 500px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  divider: {
    marginLeft: 5,
    marginRight: 5,
  },
  logo: {
    cursor: 'pointer',
  },
  phoneTitle: {
    display: 'flex',
    textAlign: 'right',
    fontWeight: '600',
    alignItems: 'center',
    fontSize: 15,
    color: 'black',
    '& svg': {
      fontSize: 16,
    },
  },
  phone: {
    display: 'block',
    fontSize: 24,
    fontWeight: 200,
    textAlign: 'right',
    lineHeight: 1,
  },
  iconHeader: {
    color: theme.palette.primary.main,
    padding: 5,
    '& span svg': {
      fontSize: 30,
    },
  },
  shoppingCart: {
    '& button': {
      padding: 5,
    },
    '& span svg': {
      fontSize: 26,
    },
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
}));

export default function Header() {
  const [openDrawerMenu, setOpenMenuDrawer] = useState(false);

  const isMobil = useMediaQuery('(max-width:950px)');
  const classes = useStylesHeader();
  const history = useHistory();
  const session = useSelector((state) => state.auth);

  const CommercialComponent = useCommercialProfile();

  return (
    <React.Fragment>
      {CommercialComponent}
      <Container>
        <div className={classes.container}>
          <div className={classes.containerLogo}>
            {isMobil && (
              <div>
                <IconButton onClick={() => setOpenMenuDrawer(true)}>
                  <MenuIcon />
                </IconButton>
                <MenuDrawer open={openDrawerMenu} onClose={() => setOpenMenuDrawer(false)} />
              </div>
            )}
            <Box>
              <img src={Logo} onClick={() => history.push('/')} className={classes.logo} alt="eltda" />
            </Box>
          </div>
          {!isMobil && <Search />}
          <div className={classes.containerTools}>
            {!isMobil && (
              <div>
                <Box display="flex" alignItems="center">
                  <Typography variant={'caption'} className={classes.phoneTitle}>
                    <PhoneIcon color="primary" />
                    &nbsp; Asesoría en Compra
                  </Typography>
                </Box>
                <Typography
                  style={{fontWeight: '900', color: '#1374EE', lineHeight: 1, fontSize: 15, textAlign: 'right'}}
                >
                  +57 323 4793073 <br />
                  385 8077
                </Typography>
              </div>
            )}
            <Divider orientation={'vertical'} className={classes.divider} />
            {session.isAuthenticate && session?.user?.company?.price_list && (
              <Button
                style={{
                  backgroundColor: '#1374EE',
                  color: 'white',
                  textTransform: 'none',
                  ...(isMobil ? {fontSize: 11, lineHeight: '11px'} : {}),
                }}
                onClick={() => history.push('/mi-cuenta/mis-productos')}
              >
                <img src={`/icons/white-document.svg`} width={20} alt="icon" />
                &nbsp;Mis productos
              </Button>
            )}
            {/* <Divider orientation={'vertical'} className={classes.divider} style={{height: 25}} /> */}
            <IconButton className={classes.iconHeader} onClick={() => history.push('/mi-cuenta')}>
              <PersonOutlineIcon />
            </IconButton>
            {/* <Divider orientation={'vertical'} className={classes.divider} style={{height: 25}} /> */}
            <div className={classes.shoppingCart}>
              <ShoppingCartButton />
            </div>
          </div>
        </div>
      </Container>
      {isMobil && (
        <Box px={1} py={2} style={{backgroundColor: '#f3f3f3'}}>
          <Search />
        </Box>
      )}
      {!isMobil && <CategoriesTopBar />}
      <PromotionalMessage />
    </React.Fragment>
  );
}
